import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MNavbar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MLoginPopup from "./MLoginPopup/MLoginPopup";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/Store/hooks";
import { SESSION_CONSTANTS } from "../../../../Services/Constants/SessionConstants";
import { UserProfileSlice } from "../../../../Store/Account/UserProfileSlice";
import LoginContainer from "../../../../Container/MyAccount/LoginContainer/LoginContainer";
import { FRONTEND_DOMAIN } from "../../../../Routing/CommonURLs/Domain";
import { MY_ACCOUNT_ROUTES } from "../../../../Routing/Path/MyAccountRoutes";
import CustomButton from "../../../InputFields/CustomButton/CustomButton";

function MNavBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openMenu, setOpenMenu] = React.useState(false);
  const [openLoginPopup, setOpenLoginPopup] = React.useState(false);

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenMenu(open);
    };

  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box className="mnavbar">
      <LoginContainer open={openLoginPopup} setOpen={setOpenLoginPopup} />
      <Grid container className="row" columnSpacing={3} alignItems={"center"}>
        <Grid xs>
          <Link className="hamburger-menu" onClick={toggleDrawer("left", true)}>
            <span></span>
          </Link>
        </Grid>
        <Grid xs textAlign="center">
          <img
            src="./images/motilalOswal-logo.svg"
            height="36px"
            alt="Motilal Oswal Insurance"
            onClick={() => navigate(COMMON_ROUTES.HOME)}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid xs textAlign={"right"}>
          {USER_DATA.mobile.value ? (
            <>
              <Button
                className="user"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img src="../images/user-icon.svg" alt="user" />
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 4px 12px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    const encodedMobile = btoa(USER_DATA.mobile.value);
                    sessionStorage.setItem(
                      SESSION_CONSTANTS.USER_MOBILE_NO,
                      encodedMobile
                    );
                    window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;
                  }}
                >
                  Dashboard
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <CustomButton
              className={"primaryBtn small"}
              text="Login"
              fullWidth={true}
              variant={"contained"}
              onClick={() => setOpenLoginPopup(true)}
            />
          )}
        </Grid>
      </Grid>

      {/* sidemenu */}
      <SwipeableDrawer
        anchor="left"
        open={openMenu}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Box className="menuWrapper">
          <Box className="topbar">
            <Link
              className="hamburger-menu active"
              onClick={toggleDrawer("left", false)}
            >
              <span></span>
            </Link>
          </Box>
          <Box className="menu-section">
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CONTACT_US)}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CLAIMS)}
                >
                  Claims
                </Link>
              </li>
            </ul>
          </Box>
          <Box className="menu-section">
            <p className="menu-heading">Products</p>
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}
                >
                  Term Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}
                >
                  Car Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}
                >
                  Two Wheeler insurance
                </Link>
              </li>
            </ul>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default MNavBar;
